import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    background: 'rgba(255, 255, 255, 0.8)',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
}));

const HeaderPaper = ({ text, title }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant="h4" align="center" gutterBottom>
        {title}
      </Typography>
      <Divider variant="middle" />
      <Typography align="center" variant="body1" gutterBottom>
        {text}
      </Typography>
    </Paper>
  );
};

export default HeaderPaper;
