import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import { graphql } from 'gatsby';

import Template from '../components/Template';
import Metadata from '../components/Metadata';
import BackgroundImage from '../components/BackgroundImage';
import HeaderPaper from '../components/HeaderPaper';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },

  headerWrapper: {
    margin: '-20px -20px 0',
  },

  contentWrapper: {
    flexGrow: 1,
    marginTop: 8,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',

      '& .MuiPaper-root': {
        height: 'calc(100% - 20px)',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

const brandValues = [
  'About.BrandValues.body.1',
  'About.BrandValues.body.2',
  'About.BrandValues.body.3',
  'About.BrandValues.body.4',
  'About.BrandValues.body.5',
];

const About = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const backgroundImg = data.aboutBG.childImageSharp.gatsbyImageData;

  return (
    <Template>
      <Metadata pageTitle={t('Menu.About')} />
      <Grid
        container
        wrap="nowrap"
        direction="column"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.headerWrapper}>
          <BackgroundImage bgImage={backgroundImg}>
            <HeaderPaper title={t('About.title')} text={t('About.body')} />
          </BackgroundImage>
        </Grid>

        <Grid
          item
          container
          spacing={2}
          alignItems="stretch"
          className={classes.contentWrapper}
        >
          <Grid item lg={5}>
            <Paper elevation={0}>
              <Typography variant="h5" gutterBottom>
                {t('About.BrandValues.title')}
              </Typography>
              <Divider />
              <List component="nav" disablePadding>
                {brandValues.map((item, key) =>
                  t(item) ? (
                    <ListItem key={key}>
                      <ListItemIcon>
                        <FiberManualRecordRoundedIcon
                          fontSize="small"
                          style={{ color: '#e42229' }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2" color="textSecondary">
                          {t(item)}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ) : null
                )}
              </List>
            </Paper>
          </Grid>

          <Grid item lg={7}>
            <Paper elevation={0}>
              <Typography variant="h5" gutterBottom>
                {t('About.Slogan.title')}
              </Typography>
              <Divider />
              <Typography
                variant="body2"
                align="justify"
                paragraph
                color="textSecondary"
                component="div"
              >
                <Box lineHeight={2}>{t('About.Slogan.body')}</Box>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Template>
  );
};

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    aboutBG: file(relativePath: { eq: "identity.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }
  }
`;
